<template>
  <div class="opinion-height">
    <!-- <el-steps direction="vertical" :active="0">
      <el-step title="提交"></el-step>
      <el-step title="部门经理审核"></el-step>
      <el-step title="部门分管高管审核"></el-step>
      <el-step title="财务分管高管"></el-step>
      <el-step title="董事长审核" description="超出一定金额后交由董事长审核"></el-step>
    </el-steps> -->
    <img src="@/assets/公共费用报销预申请流程图-竖形.png" alt="公共费用报销预申请流程图" />
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.opinion-height {
  display: flex;
  justify-content: center;
  img {
    height: 650px;
  }
}
</style>
